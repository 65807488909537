.preview-containter.container-equipo-sabalera .c1minamin {
    width: 50%;
}


.preview-containter { display: flex; flex-direction:row; max-width: 1300px;}

.preview-containter .container-mam-link .c1minamin, .preview-containter .container-mam-link .c2minamin  {
    width: 50%;
}

.preview-containter .container-mam-link .c3minamin {
   display: none;
}

.container-minamin { display: flex;	flex-direction:row; max-width: 1300px;}

.container-mam-link { display: flex; flex-direction:row; max-width: 1300px;}

.container-mam-solo { display: flex; flex-direction:row; max-width: 1300px;}

.container-equipo-undefined .content-c1mam-sabalera {display:none}
.container-equipo-undefined .content-c1mam-tatengue {display:none}

.container-equipo-otro .content-c1mam-sabalera {display:none}
.container-equipo-otro .content-c1mam-tatengue {display:none}

.container-equipo-tatengue .content-c1mam-sabalera {display:none}
.container-equipo-sabalera .content-c1mam-tatengue {display:none}


.c1minamin { 
    order: 1; 
    background-size: cover;
    display: flex;
    flex-direction: column;
    width: 33%;
    height: 100%;
}

.c2minamin { 
    order: 2; 
    background-size: cover;
    display: flex;
    flex-direction: column;
    width: 33%;
    height: 100%;
}

.c3minamin { 
    order: 3; 
    background-size: cover;
    display: flex;
    flex-direction: column;
    width: 33%;
}



.c1minamin h1 { background-color: var(--white);
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--dark-black);
    margin: 0 0 0.5rem 0;
    padding: 0;
    font-weight: 400;}
   
.c1minamin h1 strong {
        color: var(--sky-blue);
        font-weight: 400;
}

.btn-el-minamin-sabalera {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--medium-black); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0rem; 
    width: 180px; 
}

.btn-el-minamin-tatengue {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--alert-red); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0 1rem; 
    width: 180px; 
}

.btn-el-minamin {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--sky-blue); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0rem; 
    width: 180px; 
}

.c1minamin img { width: 300px; height: auto; }

.c1minamin .sobreimg {padding: 1rem;}

.c2minamin { order: 2; width: 33%;padding: 1rem; display: flex; flex-direction: column;}

.c3minamin {order: 1; width: 33%; display: grid; place-items: center;}

.embebed-minamin {
    position: relative;
    width: 80%;
    height: 118px;
    margin: 0 auto;
    overflow: hidden;
    min-height: 8rem;
}

.embebed-minamin iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%; 
}

.ad-slot { height: 100px; background-color: #CCC;} 

.container-mam-link { 
    border: 1px solid var(--shadow-gray);
    border-radius: 5px;
    padding: 2rem 0 2rem 0;
}

.container-mam-link .c1minamin { 
    order: 1; 
    width: 50%; 
    justify-content: flex-start;
    padding: 0 1rem;
}

.container-mam-link .c1minamin img {
    display: none;
}

.container-mam-link .c1minamin h1 { 
    background-color: var(--white);
    font-size: 1.75rem;
    line-height: 2.0rem;
    color: var(--dark-black);
    margin: 0.3rem 0 0 0.9rem;
    padding: 0;
    font-weight: 400;
}

.container-mam-link .c1minamin h1 strong {
    color: var(--sky-blue);
    font-weight: 400;
}

.container-mam-link .c1minamin .sobreimg {
    padding: 1rem;
}

.container-mam-link .btn-el-minamin-sabalera {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--medium-black); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0rem 1rem; 
    width: 180px; 
}

.container-msm-link .btn-el-minamin-tatengue {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--alert-red); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0rem 1rem; 
    width: 180px; 
}

.container-mam-link .btn-el-minamin {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--sky-blue); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0 1rem; 
    width: 180px; 
}


.container-mam-link .bajada-minamin{
    font-size: 0.9rem;
    font-weight: 400;
}

.container-mam-link .publi-mam{
    display: flex;
    justify-content: center;
}

.container-equipo-tatengue .contenedor-escudo {
    width: 80%;
    height: 65px; 
    padding: 0.5rem 1rem; 
    text-align: center; 
    background: url(https://www.ellitoral.com/collections/utiles-diseno/lpf-logo-mam___65e06b533ef70b001c44f1cb.png) center no-repeat, linear-gradient(to bottom left,#cfcfcf 10%,#edebeb 90%);  
    border-radius: 10px;
    margin: 0 auto; 
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.container-equipo-sabalera .contenedor-escudo {
    width: 80%; 
    height: 65px;
    padding: 0.5rem 1rem; 
    text-align: center; 
    background: url(https://www.ellitoral.com/collections/utiles-diseno/primera-nacional-horizontla___65ccb73fd9924700187f609d.png) center no-repeat, linear-gradient(to bottom left,#cfcfcf 10%,#edebeb 90%); 
    border-radius: 10px;
    margin: 0 auto; 
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.container-mam-link .c2minamin { 
    order: 2; 
    width: 50%; 
    justify-content: flex-start;
}

.container-mam-link .c2minamin .estadisticas{
    display: none; 
}

.container-mam-link .c1minamin .estadisticas a:hover {
    color: var(--ocean-blue);
}

.container-mam-link .c3minamin .embebed-minamin {
    width: 80%;
}

.container-mam-link .c3minamin {
    display: none;
}

.container-mam-link .c3minamin .contenedor-escudo {
    display: none;
}

.container-mam-link .c1minamin .ad-slot {
    text-align: center;
}

.container-mam-link .c1minamin .estadisticas {
   font-size: 0.9rem;
   color: var(--gray-text);
   border-bottom: 1px solid #ccc;
   padding: 0 0 0.5rem 0;
}

.container-mam-solo { 
    border: 1px solid var(--shadow-gray);
    border-radius: 5px;
    padding: 0rem 0;
}

.container-mam-solo .c1minamin { 
    order: 1; 
    width: 33%; 
    justify-content: flex-start;
    display: flex;
    padding: 2rem 1rem;
}

.container-mam-solo .c1mam {
    display: flex;
    justify-content: flex-start;
    padding: 2rem 1rem;
}

.container-mam-solo .c2minamin { 
    order: 2; 
    width: 33%; 
    justify-content: flex-start;
}

.container-mam-solo .c3minamin { 
    order: 3; 
    width: 33%; 
    justify-content: center;
}

.container-mam-solo .c1minamin .estadisticas {
    display: flex;
    margin: 1.5rem 0;
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--black);
    padding: 0rem 1rem;
}

.container-mam-solo .c1minamin .estadisticas a:hover {
    color: var(--ocean-blue);
}

.container-mam-solo .sobreimg {
    display: none;
}

.container-mam-solo .publi-mam {
    display: none;
}

.container-mam-solo .c2minamin .embebed-minamin {
    width: 100%;
}

.container-mam-solo .btn-el-minamin-sabalera {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--medium-black); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0rem; 
    width: 180px; 
}

.container-mam-solo .btn-el-minamin-tatengue {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--alert-red); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0rem; 
    width: 180px; 
}

.container-mam-solo .btn-el-minamin {
    font-size: 0.95rem; 
    color: var(--white); 
    border-radius: 20px; 
    background-color: var(--sky-blue); 
    padding: 0.6rem 1rem; 
    text-align: left; 
    margin: 0 1rem; 
    width: 180px; 
}

.container-mam-solo .c3minamin .contenedor-escudo, .container-mam-solo .c2minamin .contenedor-escudo  {
    display: none;
}

.container-mam-solo .c2minamin .estadisticas {
    display: none;
}

@media (max-width: 1024px){
    .container-mam-solo { 
        display: flex;	
        flex-direction:column;	
        justify-content: center; 
        border-bottom: 1px solid #CCC;
        padding: 2rem 0 0 0;
    }
    
    .container-mam-solo .c1minamin { 
        width: 100%;
        padding: 0rem 1rem; 
    }
    
    .container-mam-solo { 
        display: flex;	
        flex-direction:column;	
        justify-content: center; 
        border-bottom: 1px solid #CCC;
        padding: 2rem 0 0 0;
    }
    
    .container-mam-solo .c1minamin { 
        width: 100%;
        padding: 0rem 1rem; 
    }
    
    .container-mam-solo .c1minamin .estadisticas {
        text-align: right;
        justify-content: right;
        margin: -1rem 0 2rem 0;
        font-size: 0.82rem;
    } 
    
    .container-mam-solo .c2minamin { 
        width: 100%;
        padding: 0rem 1rem;  
    }
    
    .container-mam-solo .c3minamin { 
       width: 100%; 
       margin: 1rem 0;
    }
    
    .container-mam-solo .c2minamin { 
        width: 100%;
        padding: 0rem 1rem;  
    }
    
    .container-mam-solo .c3minamin { 
       width: 100%; 
       margin: 1rem 0;
    }


    .container-mam-link .c1minamin .estadisticas {
        display: none;
    }
}

@media (max-width: 768px){
.container-mam-link { 
    display: flex;	
    flex-direction:column;	
    justify-content: center; 
    border-bottom: 1px solid #CCC;
    padding: 2rem 0 0 0;
}

.container-mam-link .c1minamin { 
    width: 100%;         
}

.container-mam-link .c2minamin { 
    width: 100%;         
}

.container-mam-link .c3minamin { 
    display: none;         
}

.container-mam-link .c1minamin { 
    padding: 0;
}

.container-mam-link .bajada-minamin, .container-mam-link .contenedor-escudo  {
    display: none;
}

.container-mam-link .btn-el-minamin-sabalera {
    margin: 0 1rem; 
}

.container-mam-link .btn-el-minamin-tatengue {
    margin: 0 1rem; 
    }

.container-mam-link .btn-el-minamin {
    margin: 0 1rem;
}  

.container-mam-link .c1minamin h1 { 
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.75rem;
 }


.container-mam-link .sobreimg {
    padding: 0 1rem;
}
.container-mam-link .embebed-minamin {
    width: 100%;
    border-radius: 5px;
    height: 150px;
}

.container-mam-link .c2minamin .estadisticas{
    width: 100%;
    text-align: center;
}

.container-mam-solo .embebed-minamin {
    width: 100%;
    border-radius: 5px;
    height: 150px;
}
}

@media (max-width: 480px){
.container-mam-solo .c1minamin { 
    padding: 0 0 2rem 1rem; 
}

.container-mam-solo .c1minamin .estadisticas {
    display: none;
}
}
